import { css } from "@emotion/core"

const globalStyle = css`
  html {
    overflow-x: hidden;
  }
  body {
    font-family: "Prompt", "Roboto", sans-serif;
    background-color: #ffffff;
  }
  #___gatsby {
    overflow: hidden;
  }
  .gb-container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 15px;
  }
  .gb--hidden--xl,
  .gb--hidden--lg,
  .gb--hidden--md,
  .gb--hidden--sm {
    display: block;
  }
  .gb--visible--xl,
  .gb--visible--lg,
  .gb--visible--md,
  .gb--visible--sm {
    display: none;
  }
  .gb--hidden {
    display: none;
  }
  .gb--visible {
    display: block;
  }

  .sectionWrapper {
    h2.title {
      font-family: "Prompt", sans-serif;
      font-weight: 600;
      font-size: 24px;
      color: #323232;
      line-height: 132.6%;
      margin-bottom: 20px;
    }
    h2.title.white {
      color: #fff;
    }
    h2.title.yellow {
      color: #fff500;
    }
    ul.listCheck {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 16px;
        span {
          width: 28px;
          height: 28px;
          margin-right: 10px;
          background: none;
        }
        span::before {
          content: "";
          display: inline-block;
          width: 28px;
          height: 28px;
          color: #212529;
          background-image: url("images/list-check-white.png");
          background-size: 80%;
          background-position: center;
          background-color: #00c300;
          border-radius: 4px;
        }
      }
    }
  }

  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    font-family: "Prompt", sans-serif;
    font-size: 14px;
  }
  .ant-popover {
    width: 100%;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: 0;
      background-color: #075181;
      box-shadow: none;
      .ant-menu {
        color: #fff;
        background: #075181;
        border: 0;
        padding: 0;
        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
          background: #0699e2;
        }
        .ant-menu-submenu {
          padding: 0;
          .ant-menu-submenu-title {
            padding-left: 20px !important;
            margin: 0;
          }
        }
        .ant-menu-sub {
          background: #3b4453;
          .ant-menu-item {
            margin-top: 0;
            margin-bottom: 0;
            a {
              padding-left: 0;
            }
          }
          .ant-menu-item-selected {
            background-color: #fff500;
            a {
              color: #333;
            }
          }
        }
        .ant-menu-item {
          .parent {
            height: 40px;
            line-height: 40px;
            padding-left: 0;
          }
          a {
            color: #fff;
          }
        }
      }
      .ant-menu-submenu-arrow {
        color: #fff;
        right: 15px;
        &:before,
        &:after {
          background-image: linear-gradient(to right, #fff, #fff);
        }
      }
      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-title:hover {
        color: #fff;
        background: #0699e2;
        .ant-menu-submenu-arrow {
          color: #fff;
        }
      }
    }
    .ant-popover-inner-content {
      padding: 20px 0;
      color: #fff;
    }
  }
  .ant-dropdown {
    .subMenuWrapper {
      background-color: #0699e2;
      border-radius: 0;
      box-shadow: none;
      .ant-dropdown-menu-item {
        color: #fff;
        background-color: #0699e2;
        a {
          font-family: "Prompt", sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #fff;
          padding: 10px 25px;
          &:hover {
            color: #fff500;
          }
        }
      }
    }
  }

  .CookieConsent {
    align-items: center !important;
    padding: 5px;
    color: #333 !important;
    background: #fff !important;
    font-family: "Prompt", sans-serif;
    position: sticky !important;
    > div:first-of-type {
      flex: 2 !important;
      font-weight: 500;
      font-size: 14px;
      max-width: 100%;
      margin-right: 15px !important;
      a {
        font-weight: 600;
        color: #c60001;
        text-decoration: underline;
      }
    }
    > div:last-of-type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    button {
      min-width: 90px;
      height: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 10px !important;
      padding: 0 10px !important;
      font-family: "Prompt", sans-serif;
      font-weight: 500;
      font-size: 15px;
      color: #fff !important;
      background-color: #00c300 !important;
      border: 1px solid #00c300 !important;
      border-radius: 80px !important;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
      text-align: center;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      outline: none;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
    #rcc-decline-button {
      background-color: transparent !important;
      border: 1px solid #fff !important;
      filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.15));
    }
  }
  .sticky-nav {
    position: fixed !important;
    top: 0;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
    opacity: 1;
    transition: opacity 600ms ease-in;
    will-change: opacity;
  }

  .sticky-nav-hidden {
    opacity: 0.85;
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding: 0 30px;
    }
    .gb--hidden--sm {
      display: none;
    }
    .gb--visible--sm {
      display: block;
    }
    .ant-popover {
      .ant-popover-inner {
        .ant-menu {
          .ant-menu-submenu {
            padding: 0;
            .ant-menu-submenu-title {
              padding-left: 40px !important;
            }
          }
          .ant-menu-sub {
            .ant-menu-item {
              a {
                padding-left: 20px;
              }
            }
          }
          .ant-menu-item {
            .parent {
              padding-left: 20px;
            }
          }
        }
        .ant-menu-submenu-arrow {
          right: 30px;
        }
      }
    }
    .CookieConsent {
      padding: 10px 30px;
    }
  }
  @media only screen and (min-width: 768px) {
    .gb--hidden--md {
      display: none;
    }
    .gb--visible--md {
      display: block;
    }
    .sectionWrapper {
      h2.title {
        font-size: 30px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .gb--hidden--lg {
      display: none;
    }
    .gb--visible--lg {
      display: block;
    }
    .CookieConsent {
      padding: 5px 30px;
      > div:first-of-type {
        max-width: 57%;
        margin-right: 30px !important;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .gb--hidden--xl {
      display: none;
    }
    .gb--visible--xl {
      display: block;
    }
    .sectionWrapper {
      h2.title {
        font-size: 38px;
      }
    }
    .CookieConsent {
      padding: 5px 12%;
      button {
        min-width: 150px;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    .CookieConsent {
      padding: 5px 15%;
    }
  }
  @media only screen and (min-width: 1880px) {
    .CookieConsent {
      padding: 5px 25%;
    }
  }
  @media only screen and (max-width: 767px) {
    .CookieConsent {
      > div:first-of-type {
        font-size: 12px;
        margin-right: 20px !important;
        a {
          font-size: 12px;
        }
      }
      button {
        min-width: 65px;
        height: 25px;
        font-size: 12px;
      }
    }
  }
  @media only screen and (max-width: 320px) {
  }
`

export default globalStyle
