export const options = {
  headerTop: "ร้านดัง!พี่หมีโซล่าเซลล์",
  telText: "080-986-9999",
  telCTA: "tel:0809869999",
  lineText: "กดแอดไลน์ @AECbrand",
  lineCTA: " https://line.me/R/ti/p/%40aecbrand",
  youtubeUrl: "https://www.youtube.com/channel/UC6T2t2b2flh7chnSzoLfeMg/videos",
  externalContact: "https://aecbrand.com/contact-us/",
}

export default options
