import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
import ThemeContext from "../context/ThemeContext"
import { css, Global } from "@emotion/core"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "antd/dist/antd.css"
import globalStyle from "../styles/global"
import PWA from "./PWA"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const [stickyClass, setStickyClass] = useState("")

  useEffect(() => {
    window.addEventListener("scroll", stickyNavbar)
    return () => window.removeEventListener("scroll", stickyNavbar)
  }, [])

  const stickyNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 150
        ? setStickyClass("sticky-nav")
        : setStickyClass("sticky-nav-hidden")
    }
  }

  return (
    <ThemeContext.Consumer>
      {() => (
        <Fragment>
          {typeof window !== `undefined` &&
          window.navigator.userAgent.indexOf("Chrome-Lighthouse") === -1 ? (
            <Helmet>
              <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TM3B62JV');`}</script>
            </Helmet>
          ) : null}
          <Global
            styles={css`
              ${globalStyle}
            `}
          />
          <CookieConsent
            flipButtons
            location="none"
            buttonText="ยอมรับ"
            declineButtonText="ปฏิเสธ"
            cookieName="gatsby-gdpr-google-tagmanager"
            containerClasses={`CookieConsent ${stickyClass}`}
          >
            เพื่อการใช้งานเว็บไซต์ได้อย่างมีประสิทธิภาพ เราจึงมีการใช้คุกกี้
            อ่าน <Link to="/cookies-policy/">นโยบายคุกกี้</Link> เพิ่มเติม
          </CookieConsent>
          {children}
          <PWA />
        </Fragment>
      )}
    </ThemeContext.Consumer>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
