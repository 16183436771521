import React from "react"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const StyleWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  .col-footercall {
    width: 100%;
  }
  .call-head {
    width: 100%;
    background: #c60001;
    padding: 5px 5px;
    text-align: center;
    .head {
      font-family: "Prompt", sans-serif;
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 400;
      color: #fff;
      a {
        color: #fff500;
        margin-left: 10px;
      }
    }
    .txt-tel {
      font-family: "Prompt", sans-serif;
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400;
      a {
        color: #fff;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 320px) {
    .list {
      .buttom {
        a {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
`

const CallToAction = () => {
  return (
    <StyleWrapper>
      <div>
        <Row>
          <Col className="call-head">
            <h3 className="head">สอบถามเพิ่มเติม 
              <a href="tel:0809869999" target="_blank" rel="noreferrer">
                คลิกที่นี่
              </a>
            </h3>
            <p className="txt-tel">
              <a href="tel:0809869999" target="_blank" rel="noreferrer">
                TEL.080-986-9999
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </StyleWrapper>
  )
}

export default CallToAction
