import React from "react"
import styled from "@emotion/styled"

const SectionWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background: #0699e2;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  text-align: center;
`

const HeaderTop = ({ text }) => {
  return <SectionWrapper>{text}</SectionWrapper>
}

export default HeaderTop
